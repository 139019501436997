
.sandboxStripe {
  min-height: 20px;
  height: 100%;
  /* max-height: 35px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f97316;
  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
  );
  background-size: 45px 45px;
  padding: 2px 8px;
}
